import React from "react";

function BrazeCards(props) {

  const {
    campaignid
  } = props;



  return (
    <div id="brazeContainer" data-campaignid={campaignid}>
     
    </div>
  )
 
}

export default BrazeCards;
