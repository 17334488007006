// extracted by mini-css-extract-plugin
export var MuiIconButtonRoot = "sidebar-module--MuiIconButton-root--EffqG";
export var MuiIconRoot = "sidebar-module--MuiIcon-root--fgSgr";
export var blueBgText = "sidebar-module--blueBgText--8qf4X";
export var boxes = "sidebar-module--boxes--jH-04";
export var boxesCoursePage = "sidebar-module--boxesCoursePage--J7ZHJ";
export var campusTextContainer = "sidebar-module--campusTextContainer--LqbhN";
export var checkboxSpan = "sidebar-module--checkboxSpan--k35aG";
export var checkedLabel = "sidebar-module--checkedLabel--1z4Qu";
export var emailSignupInput = "sidebar-module--emailSignupInput--OTT1D";
export var errText = "sidebar-module--errText--GpnnT";
export var image = "sidebar-module--image--k9f9C";
export var imageLink = "sidebar-module--imageLink--c0yzE";
export var input = "sidebar-module--input--Fkrm1";
export var inputError = "sidebar-module--inputError--GqveR";
export var linkText = "sidebar-module--linkText--5WXJl";
export var linkTextBlueBg = "sidebar-module--linkTextBlueBg--jPmjz";
export var loginContainer = "sidebar-module--loginContainer--YmMFH";
export var nonCheckedLabel = "sidebar-module--nonCheckedLabel--77p+X";
export var signupTextContainer = "sidebar-module--signupTextContainer--3doQC";
export var submitButton = "sidebar-module--submitButton--JC+6B";
export var subscribedContainer = "sidebar-module--subscribedContainer--kc82-";
export var subscribedImg = "sidebar-module--subscribedImg--CMvNQ";
export var subscribedText = "sidebar-module--subscribedText--29TwC";
export var textContainer = "sidebar-module--textContainer--HFw2R";
export var textImageContainer = "sidebar-module--textImageContainer--cGLpE";
export var textImageContainerCampus = "sidebar-module--textImageContainerCampus--SFd8c";