import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import ImageLink from "./imageLink";
import TextLink from "./textLink";
import TextImageLink from "./textImageLink";
import Signup from "./signup";
import { Grid, Button, Typography, Hidden } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Snippet from "./codeSnippet";
import AskQuestionWidget from "../askQuestionWidget";
import BrazeCards from "./brazeCards.js";

const TrustPilot = loadable(() => import("./trustPilot"))
const Advert = loadable(() => import("./advert"))
const AdvertBottomWidget = loadable(() => import ("../advertBottomWidget"));
const AdvertBottomWidgetMobile = loadable(() => import("../widgets/advert/advertBottomWidgetMobile"))
const AdvertTopWidget = loadable(() => import("../widgets/advert/advertTopWidget"));
const AdvertTopWidgetMobile = loadable(() => import("../widgets/advert/advertTopWidgetMobile"));

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center",
    marginTop: props => props.widgets.widgets !== undefined && props.widgets.widgets.widgetType.title === "Advert" || "Bottom Advert" || "Snippet" ? "0" : "20px",


  },
  desktopAdZone: {
    display: 'block',
    [theme.breakpoints.down(1170)]: {
      display: 'none'
    },
  },
  mobileAdZone: {
    display: 'block',
    [theme.breakpoints.up(1170)]: {
      display: 'none'
    },
  }
}))

function WidgetComponent(props) {


  const classes = useStyles(props);
  const { widgets, target, dictionary, isCoursePage, brazeCustomAttributesSB, showGate } = props;

  const theWidget = widgets.widgets;

  if (target == "bottom") {
    return (
      <>
        {widgets && widgets.map((items) => (
          <>
            {items && items.widgetType.title == "Bottom Advert" && <div className={classes.desktopAdZone}><AdvertBottomWidget title={items.title} zoneid={items.adZone} /></div>}
            {items && items.widgetType.title == "Bottom Advert" && items.mobileAdZone && <div className={classes.mobileAdZone}><AdvertBottomWidgetMobile title={items.title} zoneid={items.mobileAdZone} /></div>}
          </>
        ))}

      </>
    )
  }
  else if (target == "top") {
    return (
      <>
        {widgets && widgets.map((items) => (
          <>
            {items && items.widgetType.title == "Header Advert" && <div className={classes.desktopAdZone}><AdvertTopWidget title={items.title} zoneid={items.adZone} /></div>}
            {items && items.widgetType.title == "Header Advert" && items.mobileAdZone && <div className={classes.mobileAdZone}><AdvertTopWidgetMobile title={items.title} zoneid={items.mobileAdZone} /></div>}
          </>
        ))}
      </>
    )
  }
  else if (target == "body") {


    return (
      <>
        <Grid className={classes.center}>

          {theWidget && theWidget.widgetType.title == "Text + link" && <TextLink title={theWidget.title} body={theWidget._rawBody} link={theWidget.linkReference} isCoursePage={isCoursePage} />}
          {theWidget && theWidget.widgetType.title == "Image + link" && <ImageLink mainImage={theWidget.mainImage} link={theWidget.linkReference} />}
          {theWidget && theWidget.widgetType.title == "Image + text + link" && <TextImageLink widget={widgets} title={theWidget.title} body={theWidget._rawBody} isCoursePage={isCoursePage} mainImage={theWidget.mainImage} link={theWidget.linkReference} />}
          {theWidget && theWidget.widgetType.title == "Sign up" && !showGate && <Signup title={theWidget.title} body={theWidget._rawBody} campaignid={theWidget.adZone}  />}
          {theWidget && theWidget.widgetType.title == "Advert" && <Advert title={theWidget.title} zoneid={theWidget.adZone} />}
          {theWidget && theWidget.widgetType.title == "Snippet" && <TrustPilot code={theWidget.codeSnippet} />}
          {theWidget && theWidget.widgetType.title == "Bottom Advert" && <div className={classes.desktopAdZone}><AdvertBottomWidget title={theWidget.title} zoneid={theWidget.adZone} /></div>}
          {theWidget && theWidget.widgetType.title == "Bottom Advert" && theWidget.mobileAdZone && <div className={classes.mobileAdZone}><AdvertBottomWidgetMobile title={theWidget.title} zoneid={theWidget.mobileAdZone} /></div>}
          {theWidget && theWidget.widgetType.title == "Braze Cards" && <BrazeCards />}
          {theWidget && theWidget.widgetType.title == "Ask Question" && <AskQuestionWidget dictionary={dictionary} />}
        </Grid>
      </>
    )
  }
  else {
    return (
      <>
        {widgets && widgets.map((items) => (
          <>
            {items.widgetType.title == "Text + link" && <TextLink title={items.title} body={items._rawBody} link={items.linkReference} isCoursePage={isCoursePage}/>}
            {items.widgetType.title == "Image + link" && <ImageLink mainImage={items.mainImage} link={items.linkReference} />}
            {items.widgetType.title == "Image + text + link" && <TextImageLink widget={widgets} title={items.title} body={items._rawBody} mainImage={items.mainImage} link={items.linkReference} isCoursePage={isCoursePage}/>}
            {items.widgetType.title == "Sign up" && !showGate && <Signup title={items.title} body={items._rawBody} campaignid={items.adZone} brazeCustomAttributesSB={brazeCustomAttributesSB}  />}
            {items.widgetType.title == "Advert" && <Advert title={items.title} zoneid={items.adZone} />}
            {items.widgetType.title == "Snippet" && <Snippet code={items.codeSnippet} />}
            {items.widgetType.title == "Braze Cards" && <BrazeCards campaignid={items.adZone} />}
          </>
        ))}

      </>
    )
  }



}


export default WidgetComponent;
