import React from "react";

function Snippet(props) {
  const { code } = props;
//Not in use
  return (
    <>
      {code && <div dangerouslySetInnerHTML={{ __html: code[0].children[0].text }} />}
    </>
  );
}

export default Snippet;
