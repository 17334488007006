import React, { useEffect, useState } from "react";

import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import ContainerForLinks from "../containerForLinks";
import * as styles from "./sidebar.module.css";

import Dialog from "../popups/askQuestionPopup";

function ImageLink(props) {
  const { mainImage, link } = props;


  if (link != null) {

    if (link.title == "Ask a question") {
      return (
        <>
          <ContainerForLinks reference={link} _type="widgetCategory">
            <ShowImg mainImage={mainImage} />
          </ContainerForLinks>

        </>
      )
    } else {
      return (
        <>
          <ContainerForLinks reference={link} _type={link._type} logSidebarLinkGoogleAnalyticsEvent={true}>
            <ShowImg mainImage={mainImage} />
          </ContainerForLinks>

        </>
      )
    }


  }
  else {
    return null;
  }


}
function ShowImg(props) {
  const { mainImage } = props;
  var imgObj = buildImageObj(mainImage);

  return (<img
    src={imageUrlFor(imgObj.asset)
      .width(300)
      .height(Math.floor((9 / 16) * 300))
      .fit("crop")
      .auto("format")
      .url()}
    className={styles.imageLink}
  />)
}


export default ImageLink;
