import React, { useEffect, useState } from "react";
import * as styles from "./footer-disclaimer.module.css";
import { useStaticQuery, graphql } from "gatsby"

const GetDictionary = () => {
  const data = useStaticQuery(graphql`
      {
         allSanityDictionary(
          filter: {key: {in: ["footer_disclaimer_title", "footer_disclaimer_body"]}}
        ) {
          nodes {
            value
            key
          }
        }
      }
    `)

  return data.allSanityDictionary.nodes;
}


export default function FooterDisclaimer({isCoursePage}){
  const [dictionary, setDictionary] = useState(GetDictionary);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    (async () => {

      setTitle(dictionary.find(x => x.key === 'footer_disclaimer_title').value);
      setBody(dictionary.find(x => x.key === 'footer_disclaimer_body').value);

    })();
  }, []);

  return (
    <div className={styles.footerDisclaimerContainer}>
      <div className={styles.footerDisclaimerInnerContainer}>
        <span className={isCoursePage ? styles.footerDisclaimerTitleCampus : styles.footerDisclaimerTitle}>{title}</span>
        {isCoursePage ? <><p className={styles.footerDisclaimerBodyCampus}>
          Boring Money is not regulated or authorised to give personal financial advice. We aim to provide information, general pointers, tips and ideas, but those wanting personalised advice or specific instructions on what to do or buy should seek out the services of a financial adviser. In these relationships you pay for advice, they tailor everything to you, and you have redress if you get dodgy advice.
          </p>
        <p className={styles.footerDisclaimerBodyCampus}>
          That said, we work very hard to bring you accurate, timely and impartial information; to work with the industry to bring you helpful, actionable information – and to serve it all up in a way which helps and makes sense. Our promise to you is that we never say anything we don’t mean; we don’t say someone’s good if we don’t think they are; and we are super transparent on our Best Buys – how we score it and why providers make the cut.
        </p> </>: <span className={isCoursePage ? styles.footerDisclaimerBodyCampus : styles.footerDisclaimerBody}>{body}</span>}

      </div>
    </div>
  );
}

//export default FooterDisclaimer;
