import React, { useEffect, useState } from "react";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../client-config";
import serializers from "../serializers";
import ContainerForLinks from "../containerForLinks";
import * as styles from "./sidebar.module.css";

function TextLink(props) {
  const { title, body, link, isCoursePage } = props;

  if (link != null) {

    if (link.title == "Ask a question") {
      return (
        <>
          <ContainerForLinks reference={link} _type="widgetCategory">
            <div className={isCoursePage ? styles.boxesCoursePage : styles.boxes}>
              <BasePortableText
                blocks={body}
                serializers={serializers}
                {...clientConfig.sanity}
              />
            </div>
          </ContainerForLinks>
        </>
      )
    } else {
      return (
        <>
          <ContainerForLinks reference={link} _type={link._type} logSidebarLinkGoogleAnalyticsEvent={true}>
            <div className={isCoursePage ? styles.boxesCoursePage : styles.boxes}>
              <BasePortableText
                blocks={body}
                serializers={serializers}
                {...clientConfig.sanity}
              />
            </div>
          </ContainerForLinks>
        </>
      )
    }


  }
  else {
    return null;
  }



}

export default TextLink;
