import React, { useEffect, useState } from "react";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../client-config";
import serializers from "../serializers";
import { buildImageObj, } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import ContainerForLinks from "../containerForLinks";
import * as styles from "./sidebar.module.css";

function TextImageLink(props) {


  const { title, body, mainImage, link, widget, isCoursePage } = props;
  if (link != null) {

    if (link.title == "Ask a question") {
      return (
        <>

          <ContainerForLinks reference={link} _type="widgetCategory">
            <div className={styles.textImageContainer}>
              {mainImage && <ShowImg className={styles.imageContainer} mainImage={mainImage} />}
              <div className={isCoursePage ? styles.campusTextContainer : styles.textContainer}>
                <BasePortableText
                  blocks={body}
                  serializers={serializers}
                  {...clientConfig.sanity}
                />
              </div>
            </div>
          </ContainerForLinks>
        </>
      )
    }
    else {
      return (
        <>
          <ContainerForLinks reference={link} _type={link._type} logSidebarLinkGoogleAnalyticsEvent={true}>
            <div className={isCoursePage ? styles.textImageContainerCampus : styles.textImageContainer}>
              {mainImage && <ShowImg className={styles.imageContainer} mainImage={mainImage} />}
              <div className={styles.textContainer}>
                <BasePortableText
                  blocks={body}
                  serializers={serializers}
                  {...clientConfig.sanity}
                />
              </div>
            </div>
          </ContainerForLinks>
        </>
      )
    }
  }
  else {
    return null;
  }


}
function ShowImg(props) {
  const { mainImage } = props;
  var imgObj = buildImageObj(mainImage);

  if (imgObj) {
    return (<img
      src={imageUrlFor(imgObj.asset)
        .width(300)
        .height(Math.floor((9 / 16) * 300))
        .fit("crop")
        .auto("format")
        .url()}
      className={styles.image}
    />)
  } else {
    return null;
  }
}

export default TextImageLink;
