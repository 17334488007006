import React, { Component, useState, useEffect, useContext } from 'react';
import { signupToBlog, createClient } from "../../lib/helpers";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../client-config";
import serializers from "../serializers";
import ContainerForLinks from "../containerForLinks";
import * as styles from "./sidebar.module.css";
import emailIcon from "./img/email-icon.svg";
import subscribed from "./img/subscribed.svg";
import Checked from "./img/checked.svg";
import NonChecked from "./img/non-checked.svg";
import { useStaticQuery, graphql } from "gatsby"
import { Icon } from "@material-ui/core";
import {
  Grid,
  Hidden,
  Button,
  Checkbox,
  Switch,
  Slider,
  Typography,
} from "@material-ui/core";
import {
  TextField,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import ReusableCheckbox from "../landingPageComponents/blocks/reusableCheckbox";
import { makeStyles } from "@material-ui/core/styles";
import { emailCaptureSubmissionLogging } from "../../lib/helpers";


const checkBoxStyles = theme => ({
  root: {
    display: 'inline-block',
    width: '47px',
    padding: '0 !important',
    marginLeft: '-.4em',
    verticalAlign: 'top',
    '&$checked': {
      color: '#3D70B2',
    },
  },
  checked: {},
});

const iconStyles = theme => ({
  root: {
  },
  errText: {
    fontWeight: 400,
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    paddingTop: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#2F2F4E",
  },
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const CustomIcon = withStyles(iconStyles)(Icon);

var errMsg = "";
const GetDictionary = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityDictionary(
        filter: {key: {in: ["signup_widget_thank_you_text"]}}
      ) {
        nodes {
          value
          key
        }
      }
    }
  `)

  return data.allSanityDictionary.nodes;
}

export function Signup(props) {

  const [username, setUsername] = useState('');
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [errors, setErrors] = useState({});
  const [formIsValid, setFormIsValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState(true);
  const [campaignid] = useState(props.campaignid);
  const [brazeCustomAttributesSB] = useState(props.brazeCustomAttributesSB);
  const [thankYouText, setThankYouText] = useState('');
  const [logGA, setLogGA] = useState(false);

  useEffect(() => {
    (async () => {
      if (logGA) emailCaptureSubmissionLogging()
    })();
  }, [logGA]);
  //console.log('formIsValid', formIsValid)
  //console.log('errors', errors.empty)
  //console.log('brazeSubscriptionGroupID.groupID,', brazeSubscriptionGroupID?.groupID)
  //console.log('brazeCustomAttributesSB', brazeCustomAttributesSB)

  //console.log('signup-props', props)

  useEffect(() => {
    if (isSubmitted && window.dataLayer) {
      window.dataLayer.push({ event: 'subscribe_form_submitted' });
      // window.signup(username, campaignid);
    }
  }, [isSubmitted]);

  const handleChange = (event) => {
    setMarketingConsent(event.target.checked);
  };

  const handleUsername = (event) => {
    const field = event.target.value;
    setUsername(field);
    setErrors({});
    setFormIsValid(true);
  };

  const submitted = (event) => {
    event.preventDefault();
    // let formIsValid = formIsValid;
    let usernName = username;
    let errors = {};


      if (!username || !formIsValid) {
        setFormIsValid(false)
        errors['empty'] = 'Please enter a valid email address';
      }

      const isValidEmailRegex = /^[\w\-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
      const isValidEmail = usernName.match(isValidEmailRegex)
      console.log('regex check', isValidEmail)
      if (isValidEmail === null) {
        setFormIsValid(false)
        errors['empty'] = 'Please enter a valid email address';
      }

      if (formIsValid && isValidEmail) {
        setLogGA(true)
        signupToBlog(username);

        console.log("campaign-sign", brazeCustomAttributesSB)
        //window.signup(
        // username,
        // brazeSubscriptionGroupID?.groupID,
        // marketingConsent,
        // brazeCustomAttributesSB
        //);

        if (brazeCustomAttributesSB[0]?.customAttributeKey){
          localStorage.setItem('custom_attr', btoa(brazeCustomAttributesSB[0].customAttributeKey + "|" + brazeCustomAttributesSB[0].customAttributeValue));
        }

        localStorage.setItem("genMkt", marketingConsent)
        localStorage.setItem('returnUrl', encodeURIComponent(window.location.pathname));

        window.location.href = "/login?returnUrl=" + encodeURIComponent(window.location.pathname) + "&usr=" + btoa(username);

        setIsToggleOn((prevState) => ({
          isToggleOn: !prevState.isToggleOn,
          isSubmitted: true,
          marketingConsent: false,
        }));

        return null;

      } else {
        setErrors(errors);
        errMsg = errors.empty;
        event.target.className = styles.inputError;
      }
  };

  const getSignupThankYouText = async function () {
    const params = {};
    const query = "*[_type == 'dictionary'][key match ['signup_widget_thank_you_text']]";

    return createClient()
      .fetch(query, params)
      .then((response) => response)
      .then((responseJson) => {
        return responseJson;
      });
  };

    return ( <div className={styles.boxes}>
      {isToggleOn && (
        <>
          <div className={styles.signupTextContainer}>
            <BasePortableText
              blocks={props.body}
              serializers={serializers}
              {...clientConfig.sanity}
            />
          </div>
          <form onSubmit={submitted} id="frmSignup">
            {errors?.empty && (
            <p id="errArea" className={styles.errText}>
              {errMsg}
            </p>
            )}
            <TextField
              id="emailField"
              variant="outlined"
              placeholder="Enter your email"
              className={styles.emailSignupInput}
              value={username}
              onChange={handleUsername}
              fullWidth
            />
            <label
              className={
                marketingConsent ? styles.checkedLabel : styles.nonCheckedLabel
              }
            >
              {/*<CustomCheckbox*/}
              {/*  icon={*/}
              {/*    <CustomIcon className={styles.customIcon}>*/}
              {/*      <img src={NonChecked}  />*/}
              {/*    </CustomIcon>*/}
              {/*  }*/}
              {/*  checkedIcon={*/}
              {/*    <CustomIcon className= { styles.customIcon }>*/}
              {/*      <img src={Checked}  />*/}
              {/*    </CustomIcon>*/}
              {/*  }*/}
              {/*  checked={marketingConsent}*/}
              {/*  onChange={handleChange}*/}
              {/*  name="marketingConsent"*/}
              {/*/>*/}
              <ReusableCheckbox
                isVisibleTheme={props.isCoursePage}
                isSidebar={true}
                handleChange={handleChange}
                checked={marketingConsent}
              />
              <span className={styles.checkboxSpan}>
                I would like to hear from you about products, events, general marketing
                and other things from Boring Money – including personalised content and
                ads - but please try not to make them as boring as everything else in
                finance!
              </span>
            </label>
            <button id="btnSignUp" className={styles.submitButton} type='submit'>
              <img src={emailIcon} class={styles.buttonImage} />
              <span>SIGN ME UP!</span>
            </button>
            <Grid item xs={12} className={styles.loginContainer}>
              <Typography className={styles === 'Half-No padding-Blue' ? styles.blueBgText : styles.whiteBgText} variant={"body1"}>
                Already have an account? <a href={"/login"} className={styles === 'Half-No padding-Blue' ? styles.linkTextBlueBg : styles.linkText}>Login</a></Typography>
            </Grid>
          </form>
        </>
      )}

      {!isToggleOn && errors.length <= 0 ? (
        <div className={styles.subscribedContainer}>
          <img src={subscribed} className={styles.subscribedImg} />
          <span className={styles.subscribedText}>{thankYouText}</span>
        </div>
      ) : null}
    </div>
  );
}

export default Signup;
