import React from "react";
import * as styles from "./askQuestionWidget.module.css";
import Dialog from "./popups/askQuestionPopup";

import { Grid, Button, Typography } from "@material-ui/core";

//<a className={styles.pinkCta} href={cta.link}>{cta.title}</a>

const AskQuestionWidget = ({ dictionary }) => {
  return (
    <Grid
      container
      className={styles.ctaWidgetContainer}
      alignItems="center"
      justify="center"
    >
      <div className={styles.leftStripe}></div>
      <Grid
        item
        spacing={3}
        xs={12}
        sm={7}
        md={7}
        lg={9}
        className={styles.leftSideTextContainer}
      >
        <span className={styles.headerText}><strong>{dictionary && dictionary.nodes.find(x => x.key === 'ask_question_widget_header').value}</strong></span>
        <span className={styles.bodyText}>{dictionary && dictionary.nodes.find(x => x.key === 'ask_question_widget_body_text').value}</span>
      </Grid>

      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        lg={3}
        spacing={3}
        className={styles.anchorContainer}
      >
        {
          <Dialog
            buttonText={
              dictionary && dictionary.nodes.find(x => x.key === 'ask_question_widget_button_text').value
            }
            noButtonHover={false}
          />
        }
        { }
      </Grid>
    </Grid>
  );
};

export default AskQuestionWidget;
